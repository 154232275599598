import React from "react"

const VideoCard = (props) => {
  var {video} = props
  const handleVideoClick = ()=>{
    props.handleVideoClick(video)
  }
  return(
    <div className="video-card hover-move">
      <div onClick={handleVideoClick} style={{backgroundImage:`url("${video.thumbnailImage.localFile.publicURL}")`}}>
        <div/>
      </div>
      <p>{video.title}</p>
      <p>{video.text}</p>
      <p>{video.postedDate}</p>
    </div>
  )
}

export default VideoCard