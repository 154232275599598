import React, {useState, useEffect} from "react"
import CancelIcon from '../global/cancel_icon'

const VideoModal = (props) => {
  const {video, active} = props
  const videoRef = React.createRef()
  const fullscreenHandler = (e) => {
    props.clearModalActive()
    e.target.onfullscreenchange = null
  }
  useEffect(() => {
    if(active){
      if(window.innerWidth <= 500){
        if (videoRef.current.requestFullscreen) {
          videoRef.current.requestFullscreen();
        } else if (videoRef.current.mozRequestFullScreen) {
          videoRef.current.mozRequestFullScreen();
        } else if (videoRef.current.webkitRequestFullscreen) {
          videoRef.current.webkitRequestFullscreen();
        } else if (videoRef.current.msRequestFullscreen) { 
          videoRef.current.msRequestFullscreen();
        }
        setTimeout(()=>{videoRef.current.onfullscreenchange = fullscreenHandler},400)
      }
      document.querySelector('footer').style.zIndex = "-1"
      videoRef.current.play()
    } else {
      videoRef.current.pause()
      videoRef.current.currentTime = 0
      document.querySelector('footer').style.zIndex = "1"
    }
  }, [active])
  return(
    <div className={`modal-container ${active&&"active"}`}>
      {video&&<div style={{backgroundColor:"lightgray"}} className={`video-modal ${active&&"active"}`}>
        <video key={video.videoFile.localFile.publicURL} ref={videoRef} style={{width:"100%"}} controls >
          <source src={video.videoFile.localFile.publicURL} type="video/mp4"/>
        </video>
        <button onClick={props.clearModalActive}><CancelIcon /></button>
      </div>}
    </div>
  )
}

export default VideoModal