import React from 'react'

class SearchIcon extends React.Component {
  render() {
    return (
      <svg
        style={this.props.svgStyles}
        height={this.props.height}
        width={this.props.width}
        fill={this.props.color}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 25.31 25.26"
        aria-hidden="true"
      >
        <path d="M24.56,21.58l-4.44-4.44a1.48,1.48,0,0,0-1.05-.44h-1.3L16.1,15a8.86,8.86,0,1,0-1,1l1.67,1.67v1.3a1.48,1.48,0,0,0,.43,1.05l4.44,4.43a1.47,1.47,0,0,0,2.09,0l.87-.86A1.47,1.47,0,0,0,24.56,21.58Zm-15.27-5a7.36,7.36,0,1,1,7.36-7.36A7.35,7.35,0,0,1,9.29,16.55Z"/>
      </svg>
    );
  }
}
SearchIcon.defaultProps = {
  color: "#1e3b4b",
  height: "15px",
  svgStyles: {pointerEvents:"none"},
  width: "15px",
};
export default SearchIcon